<template>
  <v-card class="mb-6 mt-3" elevation="0" outlined>
    <containerTitle title="Admins"> </containerTitle>
    <v-container>
      <lineFieldsArray
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="result"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="true"
        :addable="true"
        addButtTitle="Add Admin"
        linePrefix="organisationAdmins"
      ></lineFieldsArray>
    </v-container>
    <input type="hidden" :name="field" :value="JSON.stringify(value)" />
  </v-card>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  props: ["result", "field", "fieldAttributes", "value"],
  components: { lineFieldsArray, containerTitle },
  data() {
    return {
      fields: {
        id: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
        key: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
        // name: {
        //   type: "text",

        //   name: "Name",
        //   fieldAttrInput: { class: " required" },
        //   inputFieldFormatter: "organisationNameFormatter",
        // },
        email: {
          type: "text",
          name: "Email",
          fieldAttrInput: { class: " required emailCheck" },
          inputFieldFormatter: "organisationNameFormatter",
        },
        // password: {
        //   type: "text",

        //   name: "Password",
        //   fieldAttrInput: { class: "" },
        //   inputFieldFormatter: "organizationPassword",
        // },

        remove: lineButtons.remove,
      },
    };
  },
  computed: {
    pairArray() {
      let pairArray = [];
      if (Array.isArray(this.value)) {
        this.value.forEach((element) => {

          pairArray.push({
            id: element.n_id,
            key: element.n_id_key,
            // name: (element.c_firstname + " " + element.c_lastname).trim(),
            email: element.c_email,
            active:element.n_active
            //password: element.c_password,
          });
        });
      }
      return pairArray;
    },
  },
  methods: {
    checkInput(value) {
      let pairArray = [];

      value.forEach((element) => {
      //  let nameArray = element.name.trim().split(" ");
        pairArray.push({
          n_id: element.id,
          n_id_key: element.key,
          // c_firstname: nameArray[0] ? nameArray[0] : "",
          // c_lastname: nameArray[1] ? nameArray[1] : "",
          c_email: element.email,
          c_password: element.password,
        });
      });

      this.$emit("input", pairArray);
    },
  },
};
</script>


<!--template>
{$id="scriptID"|cat:rand ( 0 , 10000 )}
<script id="{$id}">
window.organizationLanguage={json_encode(result.additional.organizationLanguage)};
window.userEmails=v-if="typeof result.additional.emails)}{json_encode(result.additional.emails!='undefined'"{else}[]{/if};
window.groups=v-if="typeof result.additional.groups)}{json_encode(result.additional.groups!='undefined'"{else}[]{/if};
;
window.usersCounter=0;
$(function() {

  var $fieldsObject=$("#{$id}").closest(".fields");

  var projectID= {if !empty($templateContent.fields.n_id.value)}{$templateContent.fields.n_id.value}{else}""{/if} ;
  var projectKey= {if !empty($templateContent.fields.n_id.value)}"{calculatIdKey($templateContent.fields.n_id.value)}"{else}""{/if} ;
  let admins= {if !empty($attributes.value)}{json_encode($attributes.value)}{else}[""]{/if} ;
  var $newusersTable=$fieldsObject.find(".adminsContainer").find(".newAdmins");

  $.each(admins,function(key,value){
    insertAdmin($newusersTable,value);
  });
   redrawDeleteButtons($newusersTable);
});

function addAdmin(caller){
  var $newusersTable= $(caller).closest(".adminsContainer").find(".newAdmins");
  insertAdmin($newusersTable);
  redrawDeleteButtons($newusersTable);
}




function insertAdmin($newusersTable,data){
 
let email="";
let id="";
let n_id_key="";
let fullName="";
let required="required";
  if (typeof data!="undefined" && data!=""){
    email=data.c_email;
    id=data.n_id;
    n_id_key=data.n_id_key;
    fullName=data.c_firstname+" "+data.c_lastname;
    required="";
  }
var $stepType=$("<div class='adminLine informationLine'><div class='adminNameContainer inlineDiv'></div><div class='adminEmailContainer inlineDiv'></div><div class='adminPasswordContainer inlineDiv'></div></div>").appendTo($newusersTable);

  $stepType.find(".adminNameContainer").html("<input type='hidden'  value='" + id + "'  name='id["+window.usersCounter+"]' ><input type='hidden'  value='" + n_id_key + "'  name='id_key["+window.usersCounter+"]' ><input class='adminName mediuminput required fullNameCheck' name='adminName["+window.usersCounter+"]' value='"+fullName+"' type='text' placeholder='Full Name' maxlength='201'>");
  
  $stepType.find(".adminEmailContainer").html("<input class='adminEmail email required unique' name='adminEmail["+window.usersCounter+"]' value='"+email+"' type='text' placeholder='member@company.com' maxlength =254 >");
  $stepType.find(".adminPasswordContainer").html("<input class='adminPassword mediuminput password "+required+"' name='adminPassword["+window.usersCounter+"]' value='' type='password' placeholder='Password' maxlength =255 >");

  let $removeButtomContainer=$("<div class='inlineDiv removeContainer'></div>").appendTo($stepType);

  window.usersCounter++;
  initializeCombobox($stepType);
  toggleHelpTooltip();
  resizeForm( $newusersTable.closest(".fields"));
}




</script>
<fieldRowHeader></fieldRowHeader>
<td colspan=3>
<div class="adminsContainer" id="">
<div class="newAdmins informationContainer" style="">
<v-divider></v-divider> 
<div  class="adminHeaderline"><div class="inlineDiv adminNameContainer "><div>{language ("organisation","adminName")} *</div></div><div  class="inlineDiv adminEmailContainer"><div>{language ("organisation","adminEmail")} *</div></div><div  class="inlineDiv"><div>{language ("organisation","adminPassword")} *</div></div></div> 
</div>

<div class='addAdmin' ><button href="javascript:void(0);" onclick="addAdmin(this);return false;" class="addadminbutton"> <i class="fas fa-plus-square fs16 textbuttonicon"></i> <span class="buttLabelWithIcon">{language ("organisation","addAdmin")}</span></button></div>
</div>
</td>
</tr></template-->